import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import styles from './FilePicker.module.css';
import useSetState from '../../../utils/useSetState';

const FilePicker = ({ onChange, value = '' }) => {
  const [state, setState] = useSetState({
    changedFilePath: ''
  });

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange({ target: { value: file } });
      setState({ changedFilePath: file.name });
    }
  };

  const fileName = state.changedFilePath ? state.changedFilePath : value;
  // consts isUploadedImage

  return (
    <div className={styles.container}>
      {value ? (
        <>
          {/* {fileName.includes('https://') ? (
            <img src={fileName} />
          ) : ( */}
          <div className={styles.file__link__text}>
            {getLastFileNamePortion(fileName)}
          </div>
          {/* )} */}
          {state.changedFilePath ? null : (
            <div>
              <a className={styles.file__link} target="href" href={value}>
                <VisibilityIcon />
              </a>
            </div>
          )}
        </>
      ) : null}

      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '-5px' }}>
          <EditIcon />
        </div>
        <input
          className={styles.file__input}
          onChange={onFileChange}
          type="file"
        />
      </div>
    </div>
  );
};

export default FilePicker;

const getLastFileNamePortion = (fileName) => {
  if (fileName) {
    const splited = fileName.split('/');

    const lastPortion = splited[splited.length - 1];

    return lastPortion.includes('?') ? lastPortion.split('?')[0] : lastPortion;
  } else {
    return '';
  }
};
