
import React from "react";
import styles from './MyDeckForm.module.css';
import FilePicker from '../../lib/components/FilePicker/FilePicker';
import Button from '../../lib/components/Button/Button';


const MyDeckForm = ({ onSubmit, cta, value = {}, onChangeField }) => {   

    return (
        <form onSubmit={onSubmit} className={styles.form__container}>
            <div className={styles.input_section}>
                <p>Deck Title</p>
                <input value={value.title} onChange={onChangeField.bind(null, {fieldName: 'title'})} type='text' placeholder='Deck Name'/>
            </div>
            
            <div className={styles.input_section}>
                <p>Deck Cover Photo</p>
                <FilePicker value={value.coverImgUrl} onChange={onChangeField.bind(null, {fieldName: 'coverImgUrl'})} />
            </div>

            <Button type='submit'>{ cta }</Button>
        </form>
    );
};

export default MyDeckForm;


