
import React from "react";
import UpdateDeckContainer from '../../../Dashboard/myDecks/containers/UpdateDeckContainer';


const UpdateDeckPage = ({ location }) => {

  return (
    <UpdateDeckContainer location={location}/>
  )
}

export default UpdateDeckPage;
