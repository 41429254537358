
import React, { useContext, useEffect } from "react";
import useSetState from '../../../utils/useSetState';
import UpdateDeck from '../../myDecks/UpdateDeck';
import onSubmitUpdatedDeck from './utils/onSubmitUpdatedDeck';
import { UserContext } from '../../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../../Context/FeedbackContext/FeedbackContext';
import queryString from 'query-string';
import setInitialData from './utils/setInitialData';
import Navbar from "../../../lib/components/Navbar/Navbar";
import CenterContainer from "../../../lib/components/CenterContainer/CenterContainer";
import DashboardWrapper from "../../DashboardWrapper";
import { cloneDeep } from 'lodash';


const UpdateDeckContainer = ({ location }) => {

    const [state, setState] = useSetState(null);

    const { userGlobalState } = useContext(UserContext);
    const { setFeedbackState } = useContext(FeedbackContext);

    useEffect(() => {      
        const deckId = queryString.parse(location.search).deckId;
        setInitialData({ setState, deckId });
      }, [])    

    const onChange = (newState) => {
        setState(newState);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        onSubmitUpdatedDeck({ state, setFeedbackState, userGlobalState })
    } 

    const onChangeField = ({ fieldName }, e) => {
        const stateClone = cloneDeep(state);
        stateClone[fieldName] = e.target.value;
        setState(stateClone);
    }

    return (
        <>
            <Navbar/>
                <CenterContainer>
                    <DashboardWrapper>
                        <UpdateDeck 
                            onSubmit={onSubmit} 
                            onChange={onChange} 
                            userGlobalState={userGlobalState}
                            data={state}
                            onChangeField={onChangeField}
                        />
                    </DashboardWrapper>
                </CenterContainer>
            
        </>
    );
};

export default UpdateDeckContainer;
