import { api_base_url } from '../../../../../global_data';
import axios from 'axios';
import { navigate } from 'gatsby';

const onSubmitUpdatedDeck = async ({
  state,
  setFeedbackState,
  userGlobalState
}) => {
  try {
    setFeedbackState({
      isActive: true,
      isLoading: true
    });

    const deck = state;
    const coverImg = deck.coverImgUrl;

    if (!state.title) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'The deck title is empty'
      });
    } else if (!state.coverImgUrl) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'No image was uploaded'
      });
    } else {
      const formData = new FormData();

      formData.append('coverImg', coverImg);

      formData.append(
        'data',
        JSON.stringify({
          ...deck,
          userId: userGlobalState.user._id
        })
      );

      await axios({
        url: `${api_base_url}/decks/${deck._id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });

      navigate('/dashboard/myDecks?artistId=' + deck.artist);

      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: false,
        message: 'Saved'
      });
    }
  } catch (err) {
    console.log('err', err);
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: 'Error'
    });
  }
};

export default onSubmitUpdatedDeck;
