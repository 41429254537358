import React from 'react';
import PageHeader from '../../lib/components/PageHeader/PageHeader';
import MyDeckForm from '../components/MyDeckForm';
import Breadcrumbs from '../../lib/components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

const UpdateDeck = ({ onSubmit, userGlobalState, data, onChangeField }) => {
  return data ? (
    <>
      <Helmet>
        <title>Update Deck</title>
      </Helmet>
      <PageHeader>
        <Breadcrumbs
          crumbsArr={[
            {
              title: 'My Decks',
              to: `/dashboard/myDecks?artistId=${userGlobalState.artist_id}`
            },
            { title: data.title }
          ]}
        />
      </PageHeader>
      <MyDeckForm
        value={data}
        onSubmit={onSubmit}
        onChangeField={onChangeField}
        cta="Save"
      />
    </>
  ) : (
    '...Loading'
  );
};

export default UpdateDeck;
